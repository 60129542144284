<template>
<div class="v-input-text-editor"
  v-click-outside:mouseup="_ => blur()"
  v-loading.medium="isLoading"
  ref="root">

  <input class="v-input-text-editor__input"
    ref="input"
    type="file"
    accept="image/*"
    style="display:none">

  <div class="v-input-text-editor__label"
    v-if="label">
    {{ label }}
    <span v-if="required" style="color:red">*</span>
  </div>

  <div class="v-input-text-editor__editor"
    ref="editor"
    @click="focus()">
  </div>

  <div class="v-input-text-editor__error"
    v-if="v$.modelValue?.$error">
    Поле має бути заповнене
  </div>

</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import Quill from 'quill'
import "quill/dist/quill.snow.css";
export default {
  props: {
    isUnfolded: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    modelValue: { type: String, required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    minHeight: { type: String, required: false, default: '1em' },
    placeholder: { type: String, required: false, default: undefined },
    uploadURL: { type: String, required: false, default: undefined },
    formFields: { type: Object, required: false, default: undefined },
    toolbar: { type: Array, required: false, default: [] },
  },
  created() {
    if (!this.toolbar?.length) this.toolbarDisplayed = [
      [{ header: 1 }, { header: 2 }],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ color: [] }, { background: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [ ...this.uploadURL ? ['image'] : [] ],
      [ 'link', 'code-block' ],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [ 'clean' ],
    ]
    else this.toolbarDisplayed = this.toolbar
  },
  data() {
    return {
      v$: useVuelidate(),
      isLoading: false,
      form: new FormData(),
      indent: 0,
      quill: null,
      focus: _ => {},
      blur: _ => {},
      setContent: _ => {},
    }
  },
  beforeUnmount() {
    this.blurEditor(this.quill)
  },
  mounted() {
    const quill = new Quill(this.$refs.editor, {
      theme: 'snow',
      placeholder: this.placeholder ?? this.label,
      modules: {
        toolbar: this.toolbarDisplayed,
        clipboard: { matchVisual: false },
      },
    })
    this.setEditorContent(quill)
    quill.on('text-change', _ => this.onEditorTextChange(quill))
    quill.getModule('toolbar').addHandler('image', _ => {
      const input = this.$refs.input
      input.onchange = async event => {
        const file = event.target.files[0]
        if (file) {
          try {
            this.isLoading = true
            if (typeof this.formFields == 'object') {
              Object.entries(this.formFields).forEach(([k,v]) => {
                this.form.append(k, v)
              })
            }
            this.form.append('file', file)
            const { data: { key } } = await this.$api.post(this.uploadURL, this.form, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            const range = quill.getSelection()
            quill.insertEmbed(range.index, 'image', `${this.$s3}/${key}`)
          } 
          catch (err) {
            console.log(err)
          }
          finally {
            this.form = new FormData()
            this.$refs.input.value = ''
            this.isLoading = false
          }
        }
      }
      input.click()
    })
    this.focus = _ => this.focusEditor(quill)
    this.blur = _ => this.blurEditor(quill)
    this.setContent = _ => this.setEditorContent(quill)
    this.quill = quill
    if (this.isUnfolded) this.focus()
    else this.blur()
  },
  validations() { return {
    modelValue: {
      required: requiredIf(_ => this.required)
    },
  }},
  methods: {
    focusEditor(quill) {
      if (this.disabled) return
      this.indent = '15px'
      const editor = this.$refs.root
      editor.querySelector('.ql-editor').style.padding = "1em"
      editor.querySelector('.ql-toolbar').style.display = "block"
      editor.querySelector('.ql-container').style.border = "1px solid #ccc"
      quill.enable()
      quill.focus()
    },
    blurEditor(quill) {
      this.indent = 0
      const editor = this.$refs.root
      editor.querySelector('.ql-editor').style.padding = "0"
      editor.querySelector('.ql-toolbar').style.display = "none"
      editor.querySelector('.ql-container').style.border = "none"
      quill.disable()
    },
    setEditorContent(quill) {
      const delta = quill.clipboard.convert({ html: this.modelValue })
      quill.setContents(delta, 'silent')
    },
    onEditorTextChange(quill) {
      const editorContent = quill.getSemanticHTML()
      this.$emit('update:modelValue', editorContent?.replace(/&nbsp;/g, ' '))
    },
  },
  watch: {
    modelValue() {
      if (!this.quill.hasFocus()) {
        this.setContent()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
:deep(.ql-editor) {
  min-height: v-bind(minHeight);
  font-size: 16px;
  font-family: 'Gilroy';
  white-space: normal !important;
  line-height: 1.5;
  text-align: inherit;
  &::before {
    left: v-bind(indent);
    line-height: 1.5;
  }
}
:deep(ql-blank:focus::before) {
  content: '';
}
:deep(ol), :deep(ul) {
  padding-left: 1.5em;
  &:not(:first-child) { margin-top: .5em; }
  &:not(:last-child) { margin-bottom: 1em; }
  li {
    padding-left: .5em;
    line-height: 1.25;
  }
  li + li { margin-top: .5em; }
  li::before, li::marker {
    font-weight: bold;
  }
}
:deep(p) {
  margin: 0;
  line-height: 1.25;
  &:empty { height: 1.5em; }
  &:not(:last-child) { margin-bottom: .5em; }
}
:deep(h1) {
  margin-bottom: .75em;
  font-size: 2em;
  font-weight: 600;
  &:not(:first-child) { margin-top: 1.5em; }
}
:deep(h2) {
  margin-bottom: .75em;
  font-size: 1.5em;
  font-weight: 600;
  &:not(:first-child) { margin-top: 1.5em; }
}
:deep(h3) {
  margin-bottom: .75em;
  font-size: 1.25em;
  font-weight: 600;
  &:not(:first-child) { margin-top: 1.5em; }
}
:deep(img) {
  max-width: 100%;
  object-fit: contain;
}
:deep(table) {
  margin: 0;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: $border-radius;
  overflow: hidden;
  user-select: text;
}
:deep(th) {
  padding: 1em;
  font-weight: 700;
  border: 2px solid #fff;
  background-color: #0002;
}
:deep(td) {
  padding: .75em 1em;
  border: 2px solid #fff;
  text-align: center;
  line-height: 1.35;
}
tr:nth-child(odd) {
  background-color: #0001;
}
.v-input-text-editor {
  &__input {
    display: none;
  }
  &__label {
    margin-bottom: .7em;
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    color: #0008;
  }
  &__error {
    margin-top: 5px;
    color: #ff4d4d;
    font-size: .8em;
    font-weight: 500;
    border-top: 2px solid #ff4d4d;
  }
}
</style>
