import { createApp } from 'vue'
import App from './App.vue'
import store from './store.js'
import router from './router.js'
import posthog from 'posthog-js'
import VueHead from '@morr/vue3-head'
import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk'
import { FetchInstrumentation } from '@grafana/faro-instrumentation-fetch'
import { XHRInstrumentation } from '@grafana/faro-instrumentation-xhr'

import instance from '@/api/instance'
import popups from './plugins/popups/popups'
import vColor from './plugins/v-color.js'
import vScreen from './plugins/v-screen.js'
import "plyr/dist/plyr.css"

import ui from '@/components/ui/ui.js'
import directives from '@/directives/index.js'

const app = createApp(App)
app.use(router)
  .use(store)
  .use(VueHead)
  .use(popups)
  .use(vScreen)
  .use(vColor, {
    color0: '#EA5F47',
    color50: '#e1b74d',
    color100: '#4EA99C'
  })

Object.entries(ui).forEach(([k,v]) => app.component(k, v))
Object.entries(directives).forEach(([k,v]) => app.directive(k, v))

if (import.meta.env.VITE_ENV != 'development') {

  posthog.init('phc_4sl8xHy07CaL8eySbBqLFwSaej0zXYBdg8G7RXHZFOm', {
    api_host: 'https://primary-api.strum.education',
    autocapture: false,
    disable_surveys: true,
    enable_recording_console_log: true,
    enable_heatmaps: true
  })
  
  initializeFaro({
    url: `https://secondary-api.strum.education/collect`,
    apiKey: '5yAkB3B7q766hmEzx6',
    app: {
      name: 'strum',
      release: import.meta.env.BUILD_DATE,
      environment: window.location.hostname=='strum.education' ? 'production'
        : window.location.hostname=='strum.edumagick.com' ? 'staging' : 'dev'
    },
    instrumentations: [
      ...getWebInstrumentations({
        enablePerformanceInstrumentation: false,
        captureConsole: true,
        captureConsoleDisabledLevels: []
      }),
      new FetchInstrumentation(),
      new XHRInstrumentation(),
    ],
    view: {
      name: window.location.href
    },
    user: {
      _id: store.state.userdata._id,
      email: store.state.userdata.email,
      attributes: { role: store.state.role }
    },
    sessionTracking: {
      enabled: true,
      persistent: true,
    },
    logArgsSerializer: (args) => args.map((arg) => JSON.stringify(arg)).join(' '),
  })

}
else {
  console.log('analytics is disabled due to development mode')
}

let uuid = 0
app.mixin({
  beforeCreate() {
    this.$uuid = uuid.toString()
    uuid += 1
  },
  computed: {
    $user() { return store.state.userdata },
    $role() { return store.state.role },
    $subjectId() { return store.state.subjectId },
    $subject() { return store.state.subject },
    $course() { return store.state.course },
    $lesson() { return store.state.lesson },
    $task() { return store.state.task },
  },
})

Object.assign(app.config.globalProperties, {
  $cmain: '#433298',
  $cbg: '#f7f7f7',
  $blue: "#63c5da",
  $api: { ...instance },
  $posthog: posthog,
  $s3: 'https://cdn.strum.education',
  $validationPassword: { regexp: /^[A-Za-z0-9!#$%^*]+$/, text: "Пароль може містити лише латинські літери, цифри і символи !#$%^*" },
  $validationLogin: { regexp: /^[a-zA-Z0-9_ ]*$/, text: "Логін може містити лише латинські літери, цифри і пробіли" },
  console: _ => console,
  $formatDate: (string, withMinutes=false) => {
    const date = new Date(string)
    if (date == 'Invalid Date') return '—'
    return date.toLocaleDateString('uk-UA', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      ...withMinutes && { minute: '2-digit', hour: '2-digit' }, 
    })
  },
  $groupBy: (arrOfObjects, key) => {
    if (!arrOfObjects?.length) return {}
    return arrOfObjects.reduce((acc, obj, i) => {
      if (typeof obj != 'object') return acc
      const value = obj[key]
      if (!acc[value]) acc[value] = []
      acc[value].push({ ...obj, index: i })
      return acc
    }, {})
  },
  $answerToString(arr, type) {
    const dictionary = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'І', 'К']
    if (!Array.isArray(arr)) return '—'
    if (['choice', 'pairs', 'multiple'].includes(type)) return arr[0].map(e => dictionary[e]).join(' ')
    if (['multiple_pairs'].includes(type)) return arr.map(e => e.map(ee => dictionary[ee])).join('  ')
    return arr.map(e => e[0]).join(' ')
  },
  $download(data, type, fileName) {
    const blob = new Blob([ data ], { type })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = fileName
    a.href = url
    a.click()
    URL.revokeObjectURL(url)
  },
  $navigateLink(url, options) {
    const { isBlank, download } = options
    const a = document.createElement('a')
    a.href = url
    if (isBlank) a.target = '_blank'
    if (download) a.download = download
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  $mapList(arr, params={}) {
    const defaultParams = {
      value: '_id',
      label: 'name',
      info: 'description',
      icon: 'icon',
      color: 'color',
    }
    return arr?.map(e => Object.fromEntries(Object.entries(Object.assign(defaultParams, params)).map(([k,v]) => [k, e[typeof v == 'function' ? v(e) : v]])))
  },
})

document.addEventListener('DOMContentLoaded', async _ => {
  app.mount('#app')
  setTimeout(_ => document.dispatchEvent(new Event('app-rendered')), 100)
})

window.addEventListener('keydown', function(e) {
  if(e.code == 'Space' && e.target == document.body) {
    e.preventDefault()
  }
});

