<template>
<div class="v-avatar">

  <img class="v-avatar__avatar"
    v-if="src"
    :src="`${src}?size=${compression}`"
    @load="isLoading = false">

  <div class="v-avatar__noavatar" 
    v-else
    :label="label?.charAt(0)">
  </div>

</div>
</template>

<script>
export default {
  props: {
    src: { type: [Boolean, String], required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    color: { type: String, required: false, default: undefined },
    size: { type: String, required: false, default: '3em' },
    compression: { type: [String, Number], required: false, default: 100 },
  },
  data() {
    return {
      isLoading: !!this.src,
    }
  },
  computed: {
    colorModel() {
      return this.color ?? this.stringToHex(this.label)
    }
  },
}
</script>

<style lang="scss" scoped>
.v-avatar {
  &__noavatar {
    pointer-events: none;
    position: relative;
    width: v-bind(size);
    height: v-bind(size);
    border-radius: 50%;
    color: #fff;
    @include gradient-corner(v-bind(colorModel), .25);
    &::after {
      content: attr(label);
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%,-50%);
      font-size: calc(v-bind(size) / 2);
      line-height: calc(v-bind(size) / 2);
      text-transform: uppercase;
    }
  }
  &__avatar {
    pointer-events: none;
    display: block;
    width: v-bind(size);
    height: v-bind(size);
    border-radius: 50%;
  }
}
</style>