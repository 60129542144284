import store from '../store.js'

export default class Task {
  
  constructor(lesson, task, glossary) {

    Object.assign(this, task)

    if (['admin','superadmin'].includes(store.state.role)) {

      Object.entries({
        type: 'text',
        text: null,
        url: null,
        src: null,
        name: null,
        lang: 'sql',
        provider: 'youtube',
        questions: [],
        values: [],
        options: Array.from({ length: 2 }, (_,i) => ({ text: null, image: false, v: 0, index: i })),
        items: Array(1).fill({ text: null, image: false, v: 0 }),
        lines: Array(1).fill({ text: null, align: 'left', image: false, v: 0 }),
        settings: {},
      }).forEach(([k,v]) => {
        this[k] = task[k] ?? v
      })

    }

    else {

      this.attempt = task.attempt ?? null
      this.percentage = task.weight>0 ? Math.round(+task.score / +task.weight * 100) : 100
      this.isCompleted = this.isAnswered && task.attempt >=0 
      this.isFinished = (this.isCompleted && this.percentage==100) || (this.attempt >= task.settings?.attemptsMax)

      this.deepReplace(task, 'text|explanation', str => str.replace(/&nbsp;/g, ' '))

      try {
        if (glossary?.length) {
          const regexGlossary = this.formRegex(glossary.map(e => e.term))
          if (glossary?.length) this.deepReplace(task, 'text', str => str.replaceAll(regexGlossary, word => {
            return `<span class='tooltip' tooltip="${glossary.find(e => word.match(new RegExp(`${e.term}`, 'i')))?.definition?.replaceAll('"','&quot;')}">${word}</span>`
          }))
        }
        if (task.type=='gaps' && store.state.role=='student') {
          let i = 0
          const regexGaps = this.formRegex(task.options)
          task.text = task.text.replaceAll(regexGaps, _ => {
            return `<label>
              <input class='gap gap-${this.index}' type='radio' name='gap-${i}' ${this.isCompleted ? 'disabled' : ''}>
              <span class='gap-label'>${this.isCompleted ? task.options[task.answer[0][i++]] : ''}</span>
            </label>`
          })
        }
      }
      catch(err) {
        console.log(err)
        store.commit('updateState', { isBrowserCompatible: false })
      }

    }

    this.text = task.text

  }
  
  get isAnswered() {
    return this.answer?.every(e => e?.at(0) != undefined)
  }
  get isActivity() {
    return ['choice','pairs','multiple','multiple_pairs','short_answer','short_answers','gaps','essay','file_upload'].includes(this.type)
  }
  get isManual() {
    return ['essay','file_upload'].includes(this.type)
  }

  formRegex(arr) {
    const regexpString = `(?<=[\\s,.:;"'> ]|^)(${arr.filter(e => !!e).join('|')})(?=[\\s,.:;"'< ]|$)`
    return new RegExp(regexpString, 'gi')
  }

  deepReplace(obj, keyNamesStr, replacer) {
    if (typeof obj !== "object" || obj === null) return
    const keyNames = keyNamesStr.split('|')
    Object.entries(obj).forEach(([k,v]) => {
      if (Array.isArray(v)) {
        obj[k].forEach(e => this.deepReplace(e, keyNamesStr, replacer))
      } 
      else if (typeof v === "object" && v !== null) {
        this.deepReplace(obj[k], keyNamesStr, replacer)
      }
      else if (typeof v === "string" && keyNames.includes(k)) {
        obj[k] = replacer(v)
      } 
    })
  }

}