<template>
<div class="v-survey-item">

  <div class="v-survey-item__header"
    v-if="label">
    <div class="v-survey-item__label">
      {{ label }}
      <span v-if="required" style="color:red">*</span>
    </div>
    <div class="v-survey-item__note"
      v-if="note">
      {{ note }}
    </div>
  </div>

  <div class="v-survey-item__body">

    <v-checkbox class="v-survey-item__checkbox"
      v-if="type=='checkbox'"
      v-model="answerModel"
      :label="note">
    </v-checkbox>

    <v-input-range class="v-survey-item__range"
      v-else-if="type=='range'"
      v-model="answerModel"
      v-bind="bindinginputRange">
    </v-input-range>

    <v-input-text class="v-survey-item__textarea"
      v-else-if="type=='text' || type=='textarea'"
      v-model="answerModel"
      v-bind="bindingInputText">
    </v-input-text>

    <table class="v-survey-item__table"
      v-else-if="type=='table'">
      <thead>
        <tr>
          <th></th>
          <th class="v-survey-item__table-column-header"
            v-for="(item, j) in items">
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tr class="v-survey-item__table-row"
        v-for="(option, i) in options">
        <th class="v-survey-item__table-row-header">
          {{ option.text }}
        </th>
        <td class="v-survey-item__table-cell"
          v-for="(item, j) in items">
          <div class="v-survey-item__table-cell-content">
            <v-checkbox class="v-survey-item__checkbox"
              :id="$uuid"
              type="radio"
              shape="circle"
              mark="circle"
              borderWidth="8%"
              v-model="answerModel[i]"
              :name="`${$uuid}-${i}`"
              :disabled="item.disabled"
              :value="item.value ?? item.text"
              @update:modelValue="$emit('select', i, j)"
              @change="value => $emit('change', i, value)"
              isFilled>
            </v-checkbox>
          </div>
        </td>
      </tr>
    </table>

    <div class="v-survey-item__options"
      v-else-if="type=='choice' || type=='multiple'"
      v-empty="placeholder ?? 'Не знайдено'">

      <div class="v-survey-item__option"
        v-for="(option, i) in options">

        <v-checkbox class="v-survey-item__checkbox"
          v-model="answerModel"
          v-bind="bindingCheckbox"
          :id="`${$uuid}-${i}`"
          :name="$uuid"
          :label="option.label"
          :info="option.info"
          :info2="option.info2"
          :icon="option.icon"
          :disabled="option.disabled"
          :checked="option.checked"
          :value="option.value ?? option.text"
          :colorActive="option.color ?? $cmain"
          @update:modelValue="$emit('select', i)"
          isFilled>
        </v-checkbox>

        <transition
          name="expand"
          @enter="el => transitionEnter(el)" 
          @leave="el => transitionLeave(el)">
          <v-input-text class="v-survey-item__details"
            v-if="option.details && optionSelectedIndex==i"
            v-model="detailsModel"
            v-bind="option.details">
          </v-input-text>
        </transition>

      </div>

    </div>

  </div>

  <div class="v-survey-item__error"
    v-if="v$.answerModel.$error">
    <span v-if="type=='checkbox'">У цьому полі слід обов'язково зробити позначку</span>
    <span v-if="type=='choice'">Будь ласка, оберіть один з варіантів</span>
    <span v-if="type=='multiple'">Будь ласка, оберіть один або кілька варіантів</span>
    <span v-if="type=='table'">Будь ласка, оберіть варіант у кожному рядку</span>
  </div>

</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
export default {
  emits: [
    'update:modelValue',
    'update:details',
    'select',
    'change',
  ],
  props: {
    type: { type: String, required: false, default: 'choice', validations: val => ['checkbox', 'choice', 'multiple', 'text', 'textarea', 'range', 'table'].includes(val) },
    required: { type: Boolean, required: false, default: false },
    checked: { type: Boolean, required: false, default: false },
    modelValue: { type: [Boolean, Number, String, Array], required: false, default: null },
    details: { type: [String, Number], required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    note: { type: String, required: false, default: undefined },
    placeholder: { type: String, required: false, default: undefined },
    items: { type: Array, required: false, default: [] },
    options: { type: Array, required: false, default: [] },
    rows: { type: [String, Number], required: false, default: undefined },
    min: { type: [Number, String], required: false, default: 0 },
    max: { type: [Number, String], required: false, default: 10 },
    maxHeight: { type: String, required: false, default: 'initial' },
  },
  data() {
    return {
      v$: useVuelidate(),
      bindinginputRange: {
        min: this.min,
        max: this.max,
      },
      bindingInputText: {
        type: this.type=="text" ? "text" : "textarea",
        placeholder: this.placeholder,
        required: this.required,
        rows: this.rows,
        maxLength: 512,
      },
      bindingCheckbox: {
        type: this.type=="multiple" ? "checkbox" : "radio",
        shape: this.type=="multiple" ? "rect" : "circle",
        mark: this.type=="multiple" ? "tick" : "circle",
        colorActive: this.$cmain,
        required: this.required,
      },
    }
  },
  validations() {
    return {
      answerModel: {
        required: Array.isArray(this.answer) ? arr => arr.length : requiredIf(_ => this.required),
      },
    }
  },
  computed: {
    optionSelectedIndex() {
      if (this.type!='choice' && this.type!='multiple') return null
      return this.options.map(e => e.value ?? e.text).indexOf(this.answer)
    },
    detailsModel: {
      get() { return this.details },
      set(val) {
        this.$emit('update:details', val)
      }
    },
    answerModel: {
      get() { return this.modelValue },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
  },
  methods: {
    transitionEnter(el) {
      el.style.visibility = 'hidden'
      el.style.height = 'auto'
      this.$nextTick(_ => {
        const height = el.scrollHeight + 'px'
        el.style.visibility = 'visible'
        el.style.height = 0
        requestAnimationFrame(_ => {
          el.style.height = height
        })
      })
    },
    transitionLeave(el) {
      const height = getComputedStyle(el).height
      el.style.height = height
      requestAnimationFrame(_ => {
        el.style.height = 0
      })
    }
  },
  watch: {
    optionSelectedIndex() {
      this.detailsModel = null
    },
    answerModel: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-survey-item {
  &__header {
    &+div:not(:empty) { margin-top: 1.25em; }
  }
  &__body {
    max-height: v-bind(maxHeight);
    overflow-y: auto;
  }
  &__label {
    font-weight: 600;
  }
  &__note {
    margin-top: .5em;
    color: #0008;
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
  }
  &__table-row:nth-child(odd) {
    background: $cbg;
  }
  &__table-column-header {
    font-weight: 500;
    padding: 0.75em 1em;
  }
  &__table-row-header {
    font-weight: 500;
    text-align: left;
    padding: 0.75em 1em;
  }
  &__table-cell {
    border: none;
    padding: .75em 1em;
    min-width: 7em;
    padding: 0.75em 1em;
    text-align: center;
  }
  &__table-cell-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__options {
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
  }
  &__checkbox {
    cursor: pointer;
  }
  &__textarea {
    max-width: 60ch;
  }
  &__details {
    margin-top: .7em;
    padding-left: 2em;
    height: 2.5em;
    max-width: 35ch;
  }
  &__error {
    margin-top: 1em;
    font-size: .85em;
    color: red;
  }
}
</style>
